<template>
  <div class="main_content" :class="isSatis ? 'statis' : ''">
    <div class="title_box">
      <span></span>
      <h5 v-if="isSatis">个人列表</h5>
      <h5 v-else>个人排名</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box" v-if="isSatis" style="height: 52px">
        <div class="input_box">
          <span>姓名：</span>
          <el-input placeholder="请输入姓名" v-model="searchForm.trueName" style="width: 200px"></el-input>
        </div>
        <div class="input_box"><el-button icon="el-icon-search" class="search_btn" @click="searchBtn"></el-button></div>
      </div>
      <div class="search_box" v-else>
        <div class="input_box">
          <span>时间段：</span>
          <el-select v-model="searchForm.periodType" clearable placeholder="请选择" class="device_box">
            <el-option v-for="item in timeSlot" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="input_box">
          <span>起止时间：</span>
          <el-date-picker class="picker_box" v-model="startTime" type="date"
            :disabled="searchForm.periodType == 4 ? false : true" placeholder="选择日期">
          </el-date-picker>
          -
          <el-date-picker class="picker_box" v-model="endTime" type="date"
            :disabled="searchForm.periodType == 4 ? false : true" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="input_box">
          <span>姓名：</span>
          <el-input placeholder="请输入姓名" v-model="searchForm.trueName" class="set_input_style"></el-input>
        </div>
        <div class="input_box">
          <span>分区：</span>
          <el-input placeholder="请输入分区" v-model="searchForm.area" class="set_input_style"></el-input>
        </div>
        <div class="input_box">
          <span>小组：</span>
          <el-select v-model="searchForm.groupId" filterable clearable placeholder="请选择" class="device_box">
            <el-option v-for="item in groupList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="input_box">
          <span>地区：</span>
          <!--省市三级联动-->
          <!--<el-cascader-->
          <!--placeholder="请选择"-->
          <!--v-model="searchForm.code"-->
          <!--:options="araeList"-->
          <!--:clearable="true"-->
          <!--ref="myCascader"-->
          <!--@change="getArea"-->
          <!--&gt;-->
          <!--</el-cascader>-->
          <el-select v-model="searchForm.code" @focus="getArea" @change="getAreaCode" clearable>
            <el-option v-for="item in araeList" :value="item.regionCode" :label="item.area" />
          </el-select>
        </div>
        <div class="input_box">
          <span>社区：</span>
          <el-select v-model="searchForm.communityId" filterable clearable placeholder="请选择" class="device_box">
            <el-option v-for="item in communityList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="input_box">
          <span>打卡类型：</span>
          <el-select v-model="searchForm.tastTypes" clearable placeholder="请选择" multiple style="width: 300px">
            <el-option v-for="item in taskTlist" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="input_box"><el-button icon="el-icon-search" class="search_btn" @click="searchBtn"></el-button></div>
      </div>
      <div class="operate_btn" v-if="!isSatis">
        <el-button type="primary" icon="el-icon-download" @click="downloadFile">导出</el-button>
      </div>
    </div>
    <div class="tbale_box" :class="isSatis ? 'statis_table' : ''">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column prop="id" label="userId" width="80" />
        <el-table-column prop="ranking" label="个人整体排名" />
        <el-table-column prop="trueName" label="姓名" />
        <el-table-column prop="area" label="分区" v-if="!isSatis" />
        <el-table-column prop="groupName" label="小组" v-if="!isSatis" />
        <el-table-column prop="communityName" label="社区" v-if="!isSatis" />
        <el-table-column prop="punchAndAward" label="积分" v-if="!isSatis" />
        <!--<el-table-column
          prop="ljscore"
          label="累计附加积分" v-if="!isSatis"/>-->
        <el-table-column width="60" label="操作" v-if="isSatis">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="查看打卡详情" placement="top">
              <el-button type="primary" circle icon="el-icon-document" @click="taskDetails(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageBox.pageNum"
      :page-sizes="[10, 20, 50, 100, 200, 300, 400]" :page-size="pageBox.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="pageBox.total" />
    <!-- <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size=pageBox.pageSize
      layout="total,sizes, prev, pager, next" :total=pageBox.total>
    </new-pagination> -->
  </div>
</template>

<script>
import api from '@/api/index'
//import { chinaArea } from '@/utils/china-area-data'
import { timeSlot, taskTypeList } from '@/utils/baseData'
import moment from 'moment'
export default {
  props: {
    isSatis: {
      type: Boolean,
      required: false
    }
  },
  name: 'index',
  data() {
    return {
      timeSlot: timeSlot,
      araeList: [],
      communityList: [],
      groupList: [],
      taskTlist: taskTypeList,
      tableData: [],
      pageBox: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },

      searchForm: {
        periodType: 1,
        startTime: null,
        endTime: null,
        area: null,
        code: null,
        tastTypes: [],
        communityId: null,
        groupId: null,
        trueName: null
      },
      endTime: '',
      startTime: ''
    }
  },
  created() {
    this.fetchUserPower()
    this.getGroupList()
  },
  mounted() {

  },
  methods: {
    //根据UserID回显权限res,拿到医生权限管理的社区
    async fetchUserPower() {
      try {
        let res = await api.getUserPower(this.$store.state.userId)
        if (res.code == 200) {
          if (this.$store.state.role !== 'admin') {
            this.searchForm.communityIds = res.data.comId
          }
          this.getPersonalRanking(this.searchForm, this.pageBox)
        }
      } catch (e) {

      }
    },
    //获取小组列表
    async getGroupList() {
      let page = {
        pageSize: 99999,
        pageNum: 1
      }
      let data = {}
      try {
        let res = await api.getGroupListes(data, page)
        if (res.code == 200) {
          this.groupList = res.rows
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },

    //获取个人排名列表
    async getPersonalRanking() {
      try {
        const res = await api.personalRanking(this.searchForm, this.pageBox)
        if (res.code == 200) {
          this.tableData = res.rows
          this.pageBox.total = res.total
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.getPersonalRanking()
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      this.getPersonalRanking()
    },
    getArea() {
      this.araeList = this.$store.state.userArea
    },
    // 根据地区查询社区

    async getAreaCode(val) {
      this.searchForm.communityId = null
      let data = {
        code: val,
      }
      let pageBox = {
        pageNum: 1,
        pageSize: 99999
      }
      let res = await api.getCommunitySystemUser(data, pageBox)
      if (res.code == 200 && res.rows) {
        this.communityList = res.rows
      } else {
        this.communityList = []
      }
    },
    // 点击查询按钮
    searchBtn() {
      const _this = this
      if (_this.searchForm.periodType == 4) {
        if (_this.startTime == '' && _this.endTime == '') {
          _this.$message.warning('请选择起始时间')
          return false
        } else {
          _this.searchForm.startTime = moment(_this.startTime).format('YYYYMMDD')
          _this.searchForm.endTime = moment(_this.endTime).format('YYYYMMDD')
        }
      }
      _this.getPersonalRanking()
    },
    // 导出
    async downloadFile() {
      try {
        let res = await api.exportPerRank(this.searchForm)
        let name = '个人打卡排名'
        let blob = new Blob([res], { type: "xls" });
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = `${name}.xls`; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
        this.$message.success(`导出成功`);
      } catch (e) {

      }
    },
    //查看打卡详情
    taskDetails(row) {
      this.$emit('showDetails', row)
    },
  },
  watch: {}
}
</script>

<style scoped lang="less">
.el-pagination {
  text-align: center;
}

.set_input_style {
  width: 120px;
}

.search_box {
  flex-wrap: wrap;
  height: 90px;
}

.operate_btn {
  width: 30% !important;
  text-align: right;
}

.tbale_box {
  height: calc(100% - 193px);
}

.statis_table {
  height: calc(100% - 153px);
}

/deep/.el-select__tags {
  flex-wrap: unset;
  overflow: auto;
}

.statis {
  margin: 0 !important;
  height: 100%;
  width: 100%;
}
</style>
