<template>
  <div class="main_content" style="margin: 0;width: 100%;height: 100%">
    <div class="title_box">
      <span></span>
      <h5>打卡记录</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box" style="height: 52px;">
        姓名：
        <span>{{ taskInfo.trueName }}</span>
        总积分:
        <span>{{ taskInfo.punchAndAward }}</span>当前排名：
        <span>{{ taskInfo.ranking }}</span>
      </div>
      <div class="operate_btn">
        <el-button type="primary" icon="el-icon-download" @click="exportTask">导出</el-button>
      </div>
    </div>
    <div class="table_box">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column prop="id" label="id" width="100" />
        <el-table-column prop="taskTypeName" label="任务类型" />
        <el-table-column prop="submitTypeName" label="打卡方式" />
        <el-table-column prop="score" label="获得积分" />
        <el-table-column prop="rewardScore" label="奖励积分" />
        <el-table-column prop="sumScore" label="积分合计" />
        <el-table-column prop="created" label="获得积分时间" />
        <el-table-column prop="sourceName" label="积分来源" />
        <el-table-column width="60" label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="查看详情" placement="top">
              <el-button
                type="primary"
                circle
                icon="el-icon-document"
                @click="taskDetails(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageBox.pageNum"
      :page-sizes="[10, 20, 50, 100, 200, 300, 400]"
      :page-size="pageBox.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageBox.total"
    />
    <!-- <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size=pageBox.pageSize
      layout="total,sizes, prev, pager, next" :total=pageBox.total>
    </new-pagination>-->
    <!--打卡详情-->
    <el-dialog
      :visible.sync="taskDialog"
      width="40%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="set_dialog_style"
    >
      <div slot="title" class="dialog_title">
        <span></span>
        {{ taskInfo.trueName }}打卡详情
      </div>
      <task-info :taskInfoBox="taskInfoBox" :key="timer"></task-info>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/index'
import { Loading } from 'element-ui'
import TaskInfo from './task-info'
export default {
  components: { TaskInfo },
  props: {
    taskInfo: {
      type: Object,
      required: true
    }
  },
  name: 'person-task',
  data () {
    return {
      pageBox: {
        pageSize: 10,
        pageNum: 1,
        total: 0,
        id: null
      },
      tableData: [],
      taskDialog: false,
      taskInfoBox: {},
      timer: ''
    }
  },
  methods: {
    // 根据id查询打卡列表
    async getPersonTask () {
      try {
        const res = await api.personTaskGet(this.pageBox)
        if (res.code == 200) {
          this.tableData = res.rows
          this.pageBox.total = res.total
        }
      } catch (e) {
        this.$message.warning(e.msg)
      }
    },
    /* 一页显示多少条切换 */
    handleSizeChange (val) {
      this.pageBox.pageSize = val
      this.getPersonTask(this.pageBox)
    },
    /* 分页切换 */
    handleCurrentChange (val) {
      this.pageBox.pageNum = val
      this.getPersonTask(this.pageBox)
    },
    // 导出打卡小组打卡列表
    async exportTask () {
      if (this.taskInfo.trueName == undefined) {
        this.$message.warning('请选择一个居民打卡数据进行导出')
        return false
      }
      const name = this.taskInfo.trueName + '打卡记录'
      const loading = Loading.service({
        lock: true,
        text: name + '导出中',
        spinner: 'el-icon-loading',
        background: 'rgba(0,0,0,0.7)'
      })
      try {
        const res = await api.exportPersonTask(this.taskInfo.id)
        loading.close()
        const blob = new Blob([res], { type: 'xls' })
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = `${name}.xls` // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
        this.$message.success('导出成功')
      } catch (e) {
        this.$message.warning(e.msg)
      }
    },
    async taskDetails (row) {
      try {
        const res = await api.getTaskDetails(row.id)
        if (res.code == 200) {
          this.timer = new Date().getTime()
          if (res.data.tcommitTaskDto == null || res.data.tcommitTaskDto.topicInfos[0] == null) {
            return this.$message.warning('用户数据不全')
          }
          this.taskDialog = true
          this.taskInfoBox = res.data
        }
      } catch (e) {
        this.$message.warning(e.msg)
      }
    }
  },
  watch: {
    taskInfo: {
      handler (newVal, oldVal) {
        console.log(newVal)
        this.pageBox.id = newVal.id
        this.getPersonTask()
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="less">
.el-pagination {
  text-align: center;
}
.table_box {
  height: calc(100% - 153px);
  margin: 10px;
}

.search_box {
  span {
    color: #0bf3c2;
    font-size: 20px;
    margin-right: 10px;
  }
}
</style>
