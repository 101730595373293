<template>
  <div class="main_content">
    <div class="table_contet">
      <div class="left_table">
        <per-list  :isSatis="true" @showDetails="showDetails"></per-list>
      </div>
      <div class="right_table">
        <person-task :taskInfo="taskInfo"></person-task>
      </div>
    </div>
  </div>
</template>

<script>
    import perList from './components/person-rank'
    import PersonTask from "./components/person-task";
    export default {
        name: "index",
        data() {
            return {
              taskInfo:{}
            }
        },
      components:{
        PersonTask,
        perList
      },
        methods:{
          showDetails(data){
            this.taskInfo=data
          }
        }
    }
</script>

<style scoped lang="less">
  .table_contet{
    height: 100%;
    display: flex;
    .left_table{
      width: 30%;
      height: 100%;
      margin-right: 10px;
    }
    .right_table{
      width:70%;
      height: 100%;
    }
  }
</style>
